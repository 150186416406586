import React from "react";
import { ARTICLE_2 } from "shared/Types/articles";
import { useArticlesData } from "shared/Hooks/Articles/useArticlesData";

import { Hero, Layout, Text } from "components/BlogLayout";

const Article_2 = () => {
  const data = useArticlesData(ARTICLE_2);

  return (
    <Layout>
      <Hero data={data} />
      <Text data={data.text.first} />
      <Text data={data.text.second} />
      <Text data={data.text.third} />
      <Text data={data.text.fourth} />
      <Text data={data.text.sixth} />
      <Text data={data.text.seventh} />
      <Text data={data.text.eigth} />
      <Text data={data.text.ninth} />
    </Layout>
  );
};

export default Article_2;
